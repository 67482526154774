import React, { useEffect, useState } from "react"
import { navigate } from "gatsby"
import styled from 'styled-components'
import CardList from "../../../components/CardListing/NewCardList"
import { Anchor, Box, Grid } from "grommet"
import HeaderSideBar from "../../../components/layout/HeaderSideBarContent"
import YTVideo from "../../../components/YoutubeVideo"
import querystring from "query-string"
import Layout from "../../../containers/layout"
import Image from "../../../components/Image"
import Ad from "../../../containers/Ad"

const ImageContainer = styled.div`
  cursor: pointer;
`

const Component = ({ data, location }) => {
  const qs = querystring.parse(location.search)
  const [recentVideoDS, setRecentVideoDS] = useState([])
  const [video, setVideo] = useState(null)

  useEffect(() => {
    let result = []

    const allVideos = data.allAggVideo.edges;

    const video = allVideos
    .find(({ node }) => node.key === qs.id)
    setVideo(video)

    const recommandedVideos = allVideos.filter(({ node }) => node.channelId === video.node.channelId)
    .map(( { node }) => ({
      id: node.key,
      image: node?.thumbnails?.high?.url,
      title: node.title,
      publishTime: node.publishTime,
      channelId: node.channelId
    }))
    const channel = data.allChannel.edges
    .find(({ node }) => node.channelId === qs.id)
    
    setRecentVideoDS(recommandedVideos)
  }, [data.allAggVideo.edges])

  useEffect(() => {
    document.body.scrollTo(0, 0)
  }, [qs.id])

  const renderImage = (item, index) => {
    return (
      <ImageContainer
        onClick={() => {
          navigate(`/videos/view?id=${item.id}`)
        }}
      ><Box> 
        <Image
          src={item.image}
          imageProps={{
            fit: "cover",
            fill: "true",
          }}
        />

        {item.title} <br />
        
        </Box>
      </ImageContainer>
    )
  }
  return (
    <Layout max="100%">
      
      <Box  justify="center" align="center">
      <Box width="640px">
        <YTVideo videoId={qs.id} />
      </Box>
      <Box>
        <Ad />  
      </Box>
      <Box margin="10px" width="80%" direction="row"> 
     
      <CardList
            
              render={renderImage}
              dataList={recentVideoDS}
            />
      </Box>
      </Box>
    </Layout>
  )
}

export default Component

export const query = graphql`
query MyQ {
  allAggVideo(sort: {fields: publishedAt, order: DESC}) {
    edges {
      node {
        key
        channelId
        title
        thumbnails {
          high {
            url
          }
        }
        publishedAt
        publishTime
      }
    }
  }

  allChannel {
    edges {
      node {
        id
        title
        channelId
      }
    }
  }
}
`
